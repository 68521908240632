import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";
import GreenCheckMark from '../components/icons/GreenCheckMark'
import {CarouselComponent, CarouselComponentSlide} from '../components/carousel';
import "../../scss/main.scss";

export default function Approach() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Live-in Care</title>
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<section>
						<div className="livein-landing">
							<StaticImage 
								src="../../static/assets/marketing-site/images/live-in-image-01.jpg" 
								loading="eager"
								placeholder="blurred"
							/>
							<div className="landing-context">
								<h1>Live-in care is the new normal.</h1>
								<br />
								<p className="primary">
									Live-in care is when a professional care comes and lives with
									you in your home to provide you care, companionship and
									support so you live a better quality of life, in the comfort
									of the familiarity of your own home.{" "}
								</p>
								<br />
								<p className="primary">
									20 years ago when families were faced with the realisation
									that their loved one or family member needed care the only
									option was to move into a care home. Live-in care is fast
									becoming the preferred choice for those who wish to receive 1
									to 1 care whilst maintaining the independence of living in
									their own home.
								</p>
								<br />
								<div className="tip">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-tip.svg" />
									<p className="secondary">
										According to a survey conducted by the Live-in Care Hub, 97%
										of people given the choice would prefer to carry on living
										at home.
									</p>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="care-benefits wide-view">
							<h1> What can my care help with </h1>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-04.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Medication management</h3>
									<br />
									<p className="primary">
										Our carers are trained to proactively and efficiently
										administer medication to ensure your wellbeing.
									</p>
								</div>
							</div>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-07.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Meal preparation</h3>
									<br />
									<p className="primary">
										Carers will plan your meals according to your dietary
										requirements, nutritional needs and personal preferences,
										including cooking your favourite meals and shopping for your
										groceries.
									</p>
								</div>
							</div>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-05.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Personal hygiene</h3>
									<br />
									<p className="primary">
										Our carers can provide assistance with dressing, grooming,
										bathing and toileting.
									</p>
								</div>
							</div>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-02.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Support with mobility</h3>
									<br />
									<p className="primary">
										Our carers are trained to ensure they help their clients
										move about safely whether this with mobilities aids or
										hoists.
									</p>
								</div>
							</div>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-06.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Housekeeping</h3>
									<br />
									<p className="primary">
										Our carers can help out with chores like laundry, light
										cleaning, linen and taking out rubbish.
									</p>
								</div>
							</div>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-01.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Emotional support</h3>
									<br />
									<p className="primary">
										Your care plan will outline all your social interests and
										preferences and the carer will help you maintain your
										activities and hobbies, both in and out of your home.
									</p>
								</div>
							</div>
							<div className="benefit">
								<div className="benefit-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-03.svg" />
								</div>
								<div className="benefit-descrip">
									<h3>Companionship</h3>
									<br />
									<p className="primary">
										Carefully matching the right carers for our clients helps
										build long lasting harmonious relationships, so they get to
										know your personality traits and when you need space.
									</p>
								</div>
							</div>
							<div className="benefit custom">
								<div className="benefit-descrip">
									<p className="secondary">
										We offer specialist support for conditions including:
									</p>
									<div className="benefit-list">
										<ul>
											<li>
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-arrow.svg" />
												<p>Dementia</p>
											</li>
											<li>
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-arrow.svg" />
												<p>Parkinsons</p>
											</li>
										</ul>
										<ul>
											<li>
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-arrow.svg" />
												<p>Cancer care</p>
											</li>
											<li>
												<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-arrow.svg" />
												<p>Palliative care</p>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<hr className="wide-view" />
					</section>

					<CarouselComponent title="What can my care help with" className="lic-carousel">
						<CarouselComponentSlide
							slideName="Medication Management" 
							slideText="Our carers are trained to proactively and efficiently administer medication to ensure your wellbeing." 
						>
							<StaticImage placeholder="blurred"  src="../../static/assets/marketing-site/images/livein-icon-04.svg" alt="medication management" height={200} width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`} />
						</CarouselComponentSlide>

						<CarouselComponentSlide
							slideName="Meal Preparation" 
							slideText="Carers will plan your meals according to your dietary requirements, nutritional needs and personal preferences, including cooking your favourite meals and shopping for your groceries." 
						>
								<StaticImage placeholder="blurred" objectFit={`contain`} src="../../static/assets/marketing-site/images/livein-icon-07.svg" width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`}/>
						</CarouselComponentSlide>

						<CarouselComponentSlide
							slideName="Personal hygiene" 
							slideText="Our carers can provide assistance with dressing, grooming, bathing and toileting." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-05.svg" height={200} width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`} />
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Mobility Support" 
							slideText="Our carers are trained to ensure they help their clients move about safely whether this with mobilities aids or hoists." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-02.svg" height={200} width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`}/>
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Housekeeping" 
							slideText="Our carers can help out with chores like laundry, light cleaning, linen and taking out rubbish." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-06.svg" height={200} width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`}/>
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Emotional support" 
							slideText="Your care plan will outline all your social interests and preferences and the carer will help you maintain your activities and hobbies, both in and out of your home. " 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-01.svg" height={200} width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`}/>
						</CarouselComponentSlide>
						<CarouselComponentSlide
							slideName="Companionship" 
							slideText="Carefully matching the right carers for our clients helps build long lasting harmonious relationships, so they get to know your personality traits and when you need space." 
						>
							<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-icon-03.svg" height={200} width={200} quality={'100'} objectFit={`contain`} objectPosition={`50 50`}/>
						</CarouselComponentSlide>
					</CarouselComponent>

					

					<section>
						<div className="livein-reasons">
							<h1 className="mobile-view">The benefits of live-in care</h1>
							<br />
							<div className="livein-images">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-imageclus-02.jpg" />
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-imageclus-03.jpg" />
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/livein-imageclus-04.jpg" />
								<StaticImage placeholder="blurred"
									className="stretch"
									src="../../static/assets/marketing-site/images/livein-imageclus-01.jpg"
								/>
								<StaticImage placeholder="blurred"
									className="stretch"
									src="../../static/assets/marketing-site/images/livein-imageclus-05.jpg"
								/>
							</div>
							<div className="livein-context">
								<h1>
									The benefits of
									<br />
									live-in care
								</h1>
								<br />
								<ul>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />
										<div className="livein-list">
											<h3>Comfort at home</h3>
											<br />
											<p className="primary">
												Enduring the stress, upheaval and uncertainty of moving
												into a care home is tough. There is no place like home
												with it’s familiarity and possessions around you.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />
										<div className="livein-list">
											<h3>Tailored to you</h3>
											<br />
											<p className="primary">
												Our carefully matched carers get to know your loved
												one’s like they were a family member.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />
										<div className="livein-list">
											<h3>Maintaining your lifestyle</h3>
											<br />
											<p className="primary">
												One-to-one care enables your loved one to live the life
												they wish - no rigid timetables or set routines.
												Continue to live to their rhythm with the additional
												help they require.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />

										<div className="livein-list">
											<h3>With professional carers</h3>
											<br />
											<p className="primary">
												It’s no surprise the calibre of carers and our training
												sets us apart: only 5% of applicants meet our standards.
												We then train them in-house to ensure they understand
												our values.
											</p>
										</div>
									</li>
									<li>
										<GreenCheckMark className='livein-reasons__green-check-mark' />

										<div className="livein-list">
											<div className="livein-list">
												<h3>More affordable</h3>
												<br />
												<p className="primary">
													Surprisingly to many live-in care is often more cost
													effective than people initially realise. A popular
													misconception is that 24 hour care is prohibitively
													expensive when compared to the cost of a care home.
													<br />
													<br />
													Care home: £1,000 / Live-in care: starting at £950
												</p>
												<Link to="/pricing" className="button-link">
													<button className="btn-primary">
														Learn more about our pricing
													</button>
												</Link>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</section>

					<section>
						<div className="livein-banner">
							<div className="banner-brochure">
								<StaticImage placeholder="blurred"
									src="../../static/assets/marketing-site/images/banner-brochure.svg"
									objectFit='contain'
								/>
								<h3>
									Discover what exceptional live-in care looks like with our
									brochure
								</h3>
								<Link to="" className="button-link">
									<button className="btn-primary">Read brochure</button>
								</Link>
							</div>
							<div className="banner-quote">
								<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/quotation-mark.svg"
										objectFit='contain'
										className="quotation-mark"
								/>
								<h3 className="small">
									Care homes used to be the default for individuals who are in
									need of care, but now 99% of those in their later years can
									stay at home with regulated live-in care. Edyn’s service is
									changing care”
								</h3>
								<div className="customer-images">
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/banner-brochure-img.svg"
										objectFit='contain'
									/>
									<div className="customer-names">
										<p className="secondary pro">
											Jai Smith
											<br />
											<span>
												Edyn customer <br />
												Dementia live-care
											</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="livein-compare">
							<h2>How we compare</h2>

							<p className="secondary heading no-border">Care home</p>
							<p className="secondary heading no-border">
								Introductory agencies
							</p>
							<div className="compare-item green no-border heading">
								<StaticImage placeholder="blurred"
									style={{width: '50%'}}
									src="../../static/assets/marketing-site/images/logo.png"
									objectFit='contain'
								/>
							</div>

							<p className="secondary sub-heading">Trained carers</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">1:1 support</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">Dedicated care team</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">Comfort of your own home</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">Connect to your community</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">
								Oversign of care, nutrition and medication
							</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">Understanding of well being</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary sub-heading">Fully managed</p>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item green mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>

							<p className="secondary no-border sub-heading">Family can stay anytime</p>
							<div className="compare-item no-border mobile">
								<div className="compact-view">
									<p className="secondary no-border">Care home</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/notincluded-compare.svg"
								/>
							</div>
							<div className="compare-item no-border mobile">
								<div className="compact-view">
									<p className="secondary no-border">Trained Carers</p>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
							<div className="compare-item green no-border mobile last">
								<div className="compact-view">
									<div className="compare-item green no-border">
										<StaticImage placeholder="blurred"
											style={{width: '50%'}}
											src="../../static/assets/marketing-site/images/logo.png"
											objectFit='contain'
										/>
									</div>
								</div>
								<StaticImage placeholder="blurred"
									className="icon-default checkmark"
									src="../../static/assets/marketing-site/images/included-compare.svg"
								/>
							</div>
						</div>
					</section>

					<section>
						<div className="livein-faq">
							<div className="faq-heading">
								<h1>FAQs</h1>
							</div>
							<div className="faq-dropdown">
								<ul>
									<li className="accordion first">
										<hr />
										<Link to="#" className="accordion-link">
											<div className="faq-arrow">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
											</div>
											<span>What do you need to provide?</span>
										</Link>
										<div
											className="accordion-items-container"
											data-collapsed="true"
										>
											<p className="primary">
												Lorem ipsum dolor sit amet consectetur adipisicing elit.
												Voluptates quo ipsa veniam dolore neque odit earum natus
												obcaecati molestias suscipit.
											</p>
										</div>
									</li>
									<li className="accordion">
										<hr />
										<Link to="#" className="accordion-link">
											<div className="faq-arrow">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
											</div>
											<span>What happens when my carer has time off?</span>
										</Link>
										<div
											className="accordion-items-container"
											data-collapsed="true"
										>
											<p className="primary">
												Lorem ipsum dolor sit amet consectetur adipisicing elit.
												Voluptates quo ipsa veniam dolore neque odit earum natus
												obcaecati molestias suscipit.
											</p>
										</div>
									</li>
									<li className="accordion">
										<hr />
										<Link to="#" className="accordion-link">
											<div className="faq-arrow">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
											</div>
											<span>
												Will I get to meet my carer before they come to live
												with me?
											</span>
										</Link>
										<div
											className="accordion-items-container"
											data-collapsed="true"
										>
											<p className="primary">
												Lorem ipsum dolor sit amet consectetur adipisicing elit.
												Voluptates quo ipsa veniam dolore neque odit earum natus
												obcaecati molestias suscipit.
											</p>
										</div>
									</li>
									<li className="accordion">
										<hr />
										<Link to="#" className="accordion-link">
											<div className="faq-arrow">
												<StaticImage placeholder="blurred"
													src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
													alt=""
												/>
											</div>
											<span>Can I get any funding for live-in care?</span>
										</Link>
										<div
											className="accordion-items-container faq"
											data-collapsed="true"
										>
											<p className="primary">
												Lorem ipsum dolor sit amet consectetur adipisicing elit.
												Voluptates quo ipsa veniam dolore neque odit earum natus
												obcaecati molestias suscipit.
											</p>
										</div>
									</li>
								</ul>
							</div>
						</div>
						<hr className="wide-view" />
					</section>

					<section className="info-block-pricing-page livein-care">
						<div className="paying-for-care">
							<div className="info-block-section center">
								<h2>Learning more about live-in care</h2>
								<div className="info-block-content">
									<div className="infoblock read-more yellow">
										<h3>Transitioning to live-in care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>Live-in care for couples</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
									<div className="infoblock read-more yellow">
										<h3>Preparing for care</h3>
										<Link className="info-read" to="">
											Read more
											<StaticImage placeholder="blurred"
												className="icon-default"
												src="../../static/assets/marketing-site/images/arrow-right.svg"
											/>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</section>
					<hr className="liveincare_mobile_only"></hr>
					<section>
						<div className="tp-divider liveincare">
							<div className="tp-left">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cqc-approved.svg" />
								<p className="secondary">
									Edyn Care is approved and regulated by the Care Quality
									commission
								</p>
							</div>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thegaurdian.png"
								alt="the Gaurdian"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thetimes.png"
								alt="the times"
								objectFit="contain"

							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/theeveningstandard.png"
								alt="evening standard"
								objectFit="contain"

							/>
						</div>
					</section>
				</main>
			</div>
			<Footer />
		</>
	);
}
